<template>
  <div class="imei-cont">
    <div class="banner">
      <img src="@/assets/img/banner.png" alt="">
    </div>
    <div class="form">
      <div class="form-group shadow">
        <div class="group-label">请输入IMEI</div>
        <div class="group-cont">
          <!-- <input type="text" v-model="form.IMEL" placeholder="请输入"> -->
          <cube-input :clearable="false" placeholder="请输入" :maxlength="15" v-model.trim="form.IMEL"></cube-input>
        </div>
      </div>
    </div>

    <div class="explain" v-show="type == 'apply'">
      <div class="flex">
        <div class="flex-left"></div>
        <div class="flex-right">
          <router-link to="/apply/explain">
            <u>服务申请说明</u>
            <img class="qe" src="@/assets/img/qe.png" alt="">
          </router-link>
        </div>
      </div>
    </div>

    <cube-button class="btn" @click="search">查询</cube-button>

  </div>
</template>

<script>
export default {
  name: 'ScarchImei',
  data () {
    return {
      type: this.$route.query.type ? this.$route.query.type : 'apply', // coupon优惠券  apply服务申请
      form: {
        IMEL: ''
      },
    }
  },
  mounted () {
    this.$cookies.set('imei', '');
  },
  methods: {
    /**
     * IMEI验证
     */
    isImei (str) {
      if(str == ''){
        this.$createToast({
          txt: '请输入IMEI',
          type: 'warn'
        }).show()
        return false;
      }else if(str == 'defaultImeiCode'){
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '抱歉，当前IMEI为默认IMEI，无法申请，请操作修改后进行申请。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
          icon: ''
        }).show()
        return false;
      }else if((/^[A-Za-z0-9]+$/.test(str))){ 
        if(str.length > 15 || str.length < 14){
          this.$createDialog({
            type: 'alert',
            title: '',
            content: '抱歉，您输入的IMEI位数不足。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
            icon: ''
          }).show()
          return false;
        }
      }else{
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '抱歉，您输入的IMEI格式不正确。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
          icon: ''
        }).show()
        return false;
      }
      return true
    },
    /**
     * 查询
     */
    search () {
      const vm = this;
      if(! this.isImei(this.form.IMEL)){
        return
      }
      let postData = {...this.form}
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/policy/queryIMEL", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            // 默认客户信息
            if(response.data.body.message){
              vm.$store.commit('updateLoader', false);
              vm.$createDialog({
                type: 'alert',
                title: '',
                content: response.data.body.message,
                icon: ''
              }).show()
              return
            }

            if(response.data.body.queryImelList && response.data.body.queryImelList.length > 0){
              vm.goPath(postData.IMEL)
            }else{
              vm.$store.commit('updateLoader', false);
              vm.$createDialog({
                type: 'alert',
                title: '',
                content: '抱歉。当前IMEI未查询到服务信息。如损坏手机可以操作并显示，请在拨号状态下按手机"*#06#"查看需申请服务手机IMEI码；如损坏手机无法显示，请拨打400-890-0001与客户工作人员联系',
                icon: ''
              }).show()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
          vm.$store.commit('updateLoader', false);
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 跳转页面
     */
    goPath (imei) {
      if(this.type == 'apply'){
        this.$router.push('/apply/contract/' + imei)
      }else if(this.type == 'coupon'){
        this.$router.push('/coupon/contract/' + imei)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.imei-cont{
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 150px;
}
.banner{
  padding: 30px;
  img{
    display: block;
    width: 100%;
    border-radius: 14px;
  }
}
.form{
  padding: 70px 30px 50px 30px;
}
.form-group{
  background: #FFFFFF;
  border-radius: 14px;
  height: 90px;
  box-sizing: border-box;
  display: flex;
  .group-label{
    padding: 10px 30px;
    font-size: 30px;
    color: #333333;
    line-height: 70px;
  }
  .group-cont{
    flex: 10;
    input{
      border-radius: 14px;
      display: block;
      width: 100%;
      height: 90px;
      box-sizing: border-box;
      padding: 30px;
      outline: none;
      list-style: none;
      text-align: right;
      font-size: 30px;
      color: #333333;
    }
  }
}
.btn{
  width: 540px;
  height: 80px;
  padding: 0;
  line-height: 80px;
  background-color: #208170;
  border-radius: 50px;
  position: absolute;
  left: 105px;
  bottom: 50px;
}

.explain{
  text-align: right;
  padding: 30px;
  a{
    font-size: 26px;
    color: #E74857;
    text-align: center;
    line-height: 26px;
    display: flex;
  }
}
.qe{
  display: block;
  height: 30px;
  margin-left: 10px;
}
.flex{
  display: flex;
  .flex-left{
    flex: 10;
  }
}
</style>